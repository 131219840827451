export default function nameunitmeasure(unitMeasure) {
    let unit = unitMeasure.toLowerCase()
    switch(unit) {
        case 'unidades':
            return 'und'
        case 'dozens':
            return 'docena'
        default:
            return unit
    }
}