var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"GuBSnT5kmFTeD0xOBAmVv"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { isLocal, isTest, getENV } from 'modules/apiHelpers/env';
import PACKJSON from './package.json';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const environment = getENV() || 'unknown';
console.log('********************* [sentry.client.config]*******************', PACKJSON.version);
if (!isLocal() && !isTest()) {
  console.log('******************* [call sentry.init with] *****************');
  console.log(`tracesSampleRate: ${0.1} environment: ${environment} release: ${PACKJSON.version}`);
  Sentry.init({
    dsn: SENTRY_DSN || 'https://be94482afc704e37aad3d86e10510b79@o1055295.ingest.sentry.io/6046349',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.1,
    environment,
    release: `${environment}-${PACKJSON.version}`,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
