import getOrderDate from "./getorderdate"

export default async function getOrdersOfTheWeek(orders, deliveryWeeks, indexWeek) {
    const data = await orders.filter(singleOrder =>
      getOrderDate(singleOrder) < deliveryWeeks[indexWeek] &&
      getOrderDate(singleOrder) >= deliveryWeeks[indexWeek -1]
    )
    
    return data
  }
  