import React from 'react';
import { useState, useEffect } from 'react';

import { useRouter } from 'next/router';
import getuser from 'services/getuser';
import getcustomers from 'services/getcustomers';
import getproducts from '../services/getproducts';
import getcategories from '../services/getcategories';
import nameunitmeasure from '../services/nameunitmeasure';
import getshop from '../services/getshop';
import getOrders from '../services/getorders';
import getOrderDate from '../services/getorderdate';
import getOrdersOfTheWeek from '../services/getordersoftheweek';
import isLive from 'services/islive';

let AdminContext = React.createContext();
let { Provider, Consumer } = AdminContext;
function AdminProvider({ children }) {
  const [pais, setPais] = useState('');
  const [sucursal, setSucursal] = useState(null);
  const [vendedor, setVendedor] = useState(null);
  const [clientesVendedor, setClientesVendedor] = useState(null);
  const [nombreSucursal, setNombreSucursal] = useState(null);
  const [fechaEntrega, setFechaEntrega] = useState(null);
  const [semanasEntrega, setSemanasEntrega] = useState(null);
  const [email, setEmail] = useState(null);
  const [pedidos, setPedidos] = useState([]);
  const [pedidosSemana, setPedidosSemana] = useState([]);
  const [usuarioLogueado, setUsuarioLogueado] = useState(false);
  const [allPedidos, setAllPedidos] = useState(null);
  const [loading, setLoading] = useState(true);
  const [actualizar, setActualizar] = useState(false);
  const [productos, setProductos] = useState(null);
  const [pagos, setPagos] = useState([]);
  const [indexFecha, setIndexFecha] = useState(null);
  const [indexSemana, setIndexSemana] = useState(null);
  const [isShowOptions, setIsShowOptions] = useState(false);
  const [carrito, setCarrito] = useState([]);
  const [totalDescuento, setTotalDescuento] = useState(null);
  const [total, setTotal] = useState(null);
  const [detalle, setDetalle] = useState(null);
  const [clickeable, setClickeable] = useState(true);
  const [anulado, setAnulado] = useState(false);
  const [modalAgregarProductos, setModalAgregarProductos] = useState(false);
  const [pedidoActual, SetPedidoActual] = useState(null);
  const [modalAlert, setModalAlert] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [modalComprobante, setModalComprobante] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState({});
  const [modalState, setModalState] = useState(false);
  const [clienteActual, setClienteActual] = useState(null);
  const [modalDeleteOrder, setModalDeleteOrder] = useState(false);
  const [nuevaFechaEntrega, setNuevaFechaEntrega] = useState(false);
  const [showViewFilters, setShowViewFilters] = useState(false);
  const [categoriasActivas, setCategoriasActivas] = useState(null);
  const [selectedCategorie, setSelectedCategorie] = useState(null);
  const [nameCategorie, setnameCategorie] = useState('TODOS');
  const [maximoSinCuit, setMaximoSinCuit] = useState(false)
  const [clienteSinCuit, setClienteSinCuit] = useState(false)
  const [userId, setUserId] = useState(null)
  const [user, setUser] = useState(null)
  const [shop, setShop] = useState(null)
  const [orders, setOrders] = useState(null)
  const [deliveryDates, setDeliveryDates] = useState(null)
  const [deliveryWeeks, setDeliveryWeeks] = useState(null)
  const [indexWeek, setIndexWeek] = useState(null)
  const [ordersOfTheWeek, setOrdersOfTheWeek] = useState(null)
  const [removeLines, setRemoveLines] = useState([])
  const [patchLines, setPatchLines] = useState([])
  const [addLines, setAddLines] = useState([])
  const [customers, setCustomers] = useState(null)
  const [lines, setLines] = useState(null)
  const [actualCustomer, setActualCustomer] = useState(null)
  const [refreshCustomers, setRefreshCustomers] = useState(false)
  const [refreshOrders, setRefreshOrders] = useState(false)
  const [totalDeleted, setTotalDeleted] = useState(0)
  const [cartChanges, setCartChanges] = useState(false)
  const [isEnvLive, setIsEnvLive] = useState(null)
  const router = useRouter();
  const maxTotalSinCuit = 190000

  useEffect(() => {
    isLive()
      .then(setIsEnvLive)
  }, [])

  //Guardar el carrito en el sessionStorage
  useEffect(() => {
    carrito.length > 0 &&
      sessionStorage.setItem('carrito', JSON.stringify(carrito))
  }, [carrito])

  // Actualizar la variable Lines
  useEffect(() => {
    let array = []
    carrito !== [] &&
      carrito.map(singleCarrito => {
        let object = {
          product_id: singleCarrito.productid,
          quantity: {
            value: singleCarrito.cantidad.toString()
          },
          notes: ''
        }
        array.push(object)
      })
    setLines(array)
  }, [carrito])

  // Obtener el seller_id del usuario loguado
  useEffect(() => {
    getuser()
      .then(res => {
        if (res.error === 404) {
          router.replace('/')
        } else {
          setUser(res)
        }
      })
  }, [])

  // Calcular Semanas de Entrega

  useEffect(() => {
    let array = [];
    let weeks;
    if (deliveryDates !== null) {
      const firstDate = deliveryDates[0]
      const lastDate = deliveryDates[deliveryDates.length - 1]
      if (firstDate !== null && lastDate !== null) {
        weeks = ((lastDate - firstDate) / 1000 / 86400 / 7) < 1 ? 1 : ((lastDate - firstDate) / 1000 / 86400 / 7) + 1;
        for (let i = 0; i <= weeks; i++) {
          array.push(firstDate + i * 86400000 * 7);
        }
        setDeliveryWeeks(array);
        !refreshOrders && setIndexWeek(indexWeek !== null ? indexWeek : array.length - 1);
        setRefreshOrders(false)
      }
    }
  }, [deliveryDates]);

  //Shop del vendedor
  useEffect(() => {
    user !== null &&
      getshop(user.user_id)
        .then(setShop)
  }, [user]);

  // Orders del Vendedor
  useEffect(() => {
    refreshOrders &&
      getOrders(user.user_id)
        .then(res => {
          setOrders(res)
        })
  }, [refreshOrders]);

  useEffect(() => {
    (user !== null && !refreshOrders) &&
      getOrders(user.user_id)
        .then(res => {
          setOrders(res)
        })
  }, [user, refreshOrders]);

  //fechas de entrega
  useEffect(() => {
    if (orders !== null && orders.length > 0) {
      let arrayDates = []
      orders.forEach(singleOrder => {
        let orderDate = getOrderDate(singleOrder)
        const found = arrayDates.find(element => element === orderDate)
        found === undefined && arrayDates.push(orderDate)
      })
      setDeliveryDates(arrayDates.sort())
    }
  }, [orders])

  // Orders of the week
  useEffect(() => {
    if (orders !== null && deliveryWeeks !== null && indexWeek !== null) {
      getOrdersOfTheWeek(orders, deliveryWeeks, indexWeek).then(setOrdersOfTheWeek)
    }
  }, [orders, deliveryWeeks, indexWeek])


  //Clientes del Vendedor
  useEffect(() => {
    user !== null &&
      getcustomers(user.user_id)
        .then(setCustomers)
    setRefreshCustomers(false)
  }, [user, refreshCustomers]);

  // Pais
  useEffect(() => {
    shop !== null &&
      setPais(shop.country_id === 1 ? 'ar' : 'mx')
  }, [shop]);

  // Productos del vendedor
  useEffect(() => {
    shop !== null &&
      getproducts(shop.catalog_id)
        .then(setProductos)
  }, [shop]);

  // Categorias

  useEffect(() => {
    user !== null &&
      getcategories(shop.catalog_id)
        .then(res => setCategoriasActivas(res.data))
  }, [shop]);

  useEffect(() => { }, [pedidosSemana]);

  // useEffect(() => {
  //   sucursal !== null &&
  //     fechaEntrega !== null &&
  //     fetch(
  //       `/api/pedidos/${sucursal.data.pais}/${sucursal.data.url}?fecha_entrega=${fechaEntrega}`
  //     )
  //       .then((res) => res.json())
  //       .then((data) => setPedidos(data))
  //       .then(() => setActualizar(false))
  //       .then(() => setClickeable(true));
  // }, [sucursal, actualizar, fechaEntrega]);

  //Agregar productos al carrito

  const agregarProductosEnPedido = async (
    productosAgregar,
    nuevoTotal
  ) => {
    setTotal(nuevoTotal);
    let carritoActual = JSON.parse(sessionStorage.getItem('carrito')) !== null ? JSON.parse(sessionStorage.getItem('carrito')) : []
    await productosAgregar.forEach(async (element) => {
      let newElement = {
        cantidad: 1,
        nombre: element.name,
        imagen: element['master-product'].image,
        precio: parseFloat(
          element['product-discount'] ? element.price * (1 - element['product-discount'].discount_percentage / 100) : element.price
        ),
        productid: element.id,
        subtotal: parseFloat(
          element['product-discount'] ? element.price * (1 - element['product-discount'].discount_percentage / 100) : element.price
        ),
        medida: nameunitmeasure(element.unit_of_measurement.erp_name),
        escala: element.scale,
        datos_producto: element,
        original: false,
        id: null
      }
      carritoActual.push(newElement)
    });
    sessionStorage.setItem('carrito', JSON.stringify(carritoActual))
    setCarrito(carritoActual)
    setModalAgregarProductos(false);
    setCartChanges(true)
  };

  //Borrar Item Carrito

  const handleBorrar = (
    productid
  ) => {
    let carritoActual = carrito;
    let actualTotalDeleted = totalDeleted
    carritoActual.forEach((element, index) => {
      if (element.productid === productid) {
        //se borra el producto del carrito
        setTotalDeleted(actualTotalDeleted + element.subtotal)
        carritoActual.splice(index, 1);
        setCartChanges(true)
      }
    });
    sessionStorage.setItem('carrito', JSON.stringify(carritoActual));
    setCarrito(JSON.parse(sessionStorage.getItem('carrito')));
  };

  // Agregar un producto al carrito y actualizarlo

  const nuevoCarrito = (item) => {
    let newElement;
    let carritoActual = JSON.parse(sessionStorage.getItem('carrito'));

    if (carritoActual.length === 0) {
      //primer producto agregado//
      carritoActual.push(item);
      sessionStorage.setItem('carrito', JSON.stringify(carritoActual));
      sessionStorage.setItem('total', item.subtotal);
      setTotal(parseFloat(item.subtotal));
      setActualizar(true);
    } else {
      //ya existe un producto en el carrito
      let existe = false;
      carritoActual.forEach((element, index) => {
        if (element.productid === item.productid) {
          //se va a agregar un producto que ya está en el carrito
          existe = true;
          newElement = {
            cantidad: element.cantidad + item.cantidad,
            nombre: item.nombre,
            precio: parseFloat(item.precio),
            productid: item.productid,
            subtotal: parseFloat(
              ((element.subtotal * 100 + item.subtotal * 100) / 100).toFixed(2)
            ),
            medida: element.medida,
            escala: element.escala,
            original: item.original ? item.original : false,
            id: item.id
          };

          carritoActual.splice(index, 1, newElement);
          sessionStorage.setItem('carrito', JSON.stringify(carritoActual));
          sessionStorage.setItem(
            'total',
            parseFloat(((total * 100 + item.subtotal * 100) / 100).toFixed(2))
          );
          setTotal(
            parseFloat(((total * 100 + item.subtotal * 100) / 100).toFixed(2))
          );
        }
      });

      if (!existe) {
        //se va a agregar un producto que no está en el carrito
        carritoActual.push(item);
        sessionStorage.setItem('carrito', JSON.stringify(carritoActual));
        sessionStorage.setItem('total', parseFloat(total + item.subtotal));
        setTotal(
          parseFloat(((total * 100 + item.subtotal * 100) / 100).toFixed(2))
        );
      }
      setCarrito(carritoActual)
      setActualizar(true);
    }
  };

  return (
    <Provider
      value={{
        pais,
        setPais,
        actualizar,
        setActualizar,
        loading,
        sucursal,
        setSucursal,
        pedidos,
        usuarioLogueado,
        setUsuarioLogueado,
        router,
        setNombreSucursal,
        email,
        setEmail,
        fechaEntrega,
        setFechaEntrega,
        indexFecha,
        setIndexFecha,
        isShowOptions,
        setIsShowOptions,
        carrito,
        setCarrito,
        total,
        setTotal,
        detalle,
        setDetalle,
        nuevoCarrito,
        clickeable,
        setClickeable,
        handleBorrar,
        anulado,
        setAnulado,
        modalAgregarProductos,
        setModalAgregarProductos,
        agregarProductosEnPedido,
        pedidoActual,
        SetPedidoActual,
        productos,
        totalDescuento,
        setTotalDescuento,
        modalAlert,
        setModalAlert,
        allPedidos,
        paymentMethod,
        setPaymentMethod,
        modalComprobante,
        setModalComprobante,
        currentPhoto,
        setCurrentPhoto,
        modalState,
        setModalState,
        pagos,
        vendedor,
        setVendedor,
        clientesVendedor,
        indexSemana,
        setIndexSemana,
        semanasEntrega,
        pedidosSemana,
        clienteActual,
        setClienteActual,
        modalDeleteOrder,
        setModalDeleteOrder,
        setNuevaFechaEntrega,
        nuevaFechaEntrega,
        showViewFilters,
        setShowViewFilters,
        categoriasActivas,
        selectedCategorie,
        setSelectedCategorie,
        nameCategorie,
        setnameCategorie,
        maximoSinCuit,
        setMaximoSinCuit,
        clienteSinCuit,
        setClienteSinCuit,
        maxTotalSinCuit,
        user,
        setUser,
        userId,
        setUserId,
        shop,
        setCustomers,
        customers,
        lines,
        actualCustomer,
        setActualCustomer,
        refreshCustomers,
        setRefreshCustomers,
        deliveryWeeks,
        setDeliveryWeeks,
        indexWeek,
        setIndexWeek,
        ordersOfTheWeek,
        setRemoveLines,
        setAddLines,
        setPatchLines,
        removeLines,
        addLines,
        patchLines,
        setRefreshOrders,
        totalDeleted,
        setTotalDeleted,
        cartChanges,
        setCartChanges,
        isEnvLive,
        setShop
      }}
    >
      {children}
    </Provider>
  );
}

export { AdminProvider, Consumer as AdminConsumer, AdminContext };
