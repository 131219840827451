const CONFIG = {
  API: {
    CATALOGS: process.env.URL_API_CATALOGS,
    IAM: process.env.URL_API_IAM,
    LOCATIONS: process.env.URL_API_LOCATIONS,
    PAYMENTS: process.env.URL_API_PAYMENTS,
    SHOPS: process.env.URL_API_SHOPS,
    CUSTOMERS: process.env.URL_API_CUSTOMERS,
    ORDERS: process.env.URL_API_ORDERS,
  },
  BYPASS: {
    CATALOGS: Boolean(process.env.BYPASS_API_CATALOGS),
    IAM: Boolean(process.env.BYPASS_API_IAM),
    LOCATIONS: Boolean(process.env.BYPASS_API_LOCATIONS),
    PAYMENTS: Boolean(process.env.BYPASS_API_PAYMENTS),
    SHOPS: Boolean(process.env.BYPASS_API_SHOPS),
    CUSTOMERS: Boolean(process.env.BYPASS_API_CUSTOMERS),
    ORDERS: Boolean(process.env.BYPASS_API_ORDERS),
  },
  API_MOCKING: process.env.NEXT_PUBLIC_API_MOCKING === 'enabled',
  VERSION: process.env.npm_package_version,
  ENV: process.env.NEXT_PUBLIC_ENV,
};

export default CONFIG;
