import '/styles/globals.css';
import '/styles/StylesBulma.scss';
import Head from 'next/head';
import CONFIG from 'modules/config';


import { AdminProvider } from '../Context';

if (CONFIG.API_MOCKING && typeof window === 'undefined') {
  const startMocks = async () => {
    await import('modules/mocks');
  };
  startMocks();
}

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>Organizer Nilus</title>
        <link rel='icon' href='./favicon.png' />
      </Head>
      <AdminProvider>
        <Component {...pageProps} />
      </AdminProvider>

    </>
  );
}

export default MyApp;
